import React from "react"
import "../static/style/componentStyle/download-btn.scss"
import '../static/style/componentStyle/black-btn.scss';
import pryzmDatasheet from "../static/pdfs/PRYZM_Datasheet.pdf"
import empowerFoundationDataSheet from "../static/pdfs/emPower_Foundation_Datasheet.pdf"
import EbookDataSheet from "../static/pdfs/Ebook.pdf"
import lumiqDataSheet from "../static/pdfs/LUMIQ_Data_Sheet.pdf"
import lumiqInfoSheet from "../static/pdfs/LUMIQ_infosheet_compressed.pdf"
import CustomerSuccessBook from "../static/pdfs/CustomerSuccessBook.pdf"
import "../static/style/componentStyle/underlineButton.scss"

function DownloadBtn({ text, pdf }) {
  return (
    <>
      {pdf ? (
        <a
          href={
            pdf === "PRYZM_Datasheet.pdf"
              ? pryzmDatasheet
              : pdf === "emPOWER_DataSheet.pdf"
              ? empowerFoundationDataSheet
              : pdf === "CustomerSuccessBook.pdf"
              ? CustomerSuccessBook
              : pdf === "Ebook.pdf"
              ? EbookDataSheet
              : lumiqDataSheet
              
          }
          download={
            pdf === "PRYZM_Datasheet.pdf"
              ? "PRYZM_Datasheet"
              : pdf === "emPOWER_DataSheet.pdf"
              ? "emPower_Foundation_Datasheet"
              : pdf === "LUMIQ_infosheet.pdf"
              ? "LUMIQ_infosheet "
              : pdf === 'CustomerSuccessBook.pdf'
              ? 'Customer Success Book'
              : pdf === "Ebook.pdf"
              ? 'Gen AI Strategy'
              :"LUMIQ_DataSheet"
          }
        >
          <button className='black-btn'  >
        <span>{text}</span>
    </button>
        </a>
      ) : (
        <button className='black-btn'  >
        <span>{text}</span>
    </button>
      )}

{/* <button className='black-btn'  onClick={onClick}>
        <span>{text}</span>
    </button> */}
    </>
  )
}

export default DownloadBtn
